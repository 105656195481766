import React from 'react';
import { IRouter } from '@routers/interface';
import { BookOutlined } from '@ant-design/icons';

export const routerActivityLog: IRouter = {
  path: '/activity-log',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'activityLog.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <BookOutlined />,
  },
};
