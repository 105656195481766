export default {
  'systemManagement.router.name': 'Quản lý hệ thống',
  'systemManagement.nameVN': 'Tên tiếng Việt hệ thống',
  'systemManagement.nameEN': 'Tên tiếng Anh hệ thống',
  'systemManagement.image': 'Hình ảnh',
  'systemManagement.clientSecret': 'Mã bảo mật',
  'systemManagement.redirectUri': 'Đường dẫn truy cập',
  'systemManagement.totalUserAccess': 'Số tài khoản được phân quyền',
  'systemManagement.action': 'Hành động',
  'systemManagement.create': 'Thêm hệ thống',
  'systemManagement.webhook': 'Webhook',
  'systemManagement.id': 'Mã định danh hệ thống',
  'systemManagement.not': 'Không nhập trường này',
  'systemManagement.information': 'Thông tin hệ thống',
  'systemManagement.update': 'Cập nhật hệ thống',
  'systemManagement.documentFileVN': 'Tài liệu hướng dẫn VN (PDF)',
  'systemManagement.documentFileEN': 'Tài liệu hướng dẫn ENG (PDF)',
  'systemManagement.content.delete': 'Bạn có chắc chắn muốn xóa hệ thống này không?',
  'systemManagement.regex': 'Regex',
  'systemManagement.descriptionVN': 'Mô tả tiếng Anh',
  'systemManagement.descriptionEN': 'Mô tả tiếng Việt',
  'systemManagement.order': 'Thứ tự',

};
