import React from 'react';

import { IRouter } from '@routers/interface';

export const routerFormUserNormalAdd: IRouter = {
  path: '/user-normal/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'userNormal.add', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerFormUserNormal: IRouter = {
  path: '/user-normal/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'userNormal.edit', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
