import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';

export const routerUserNormal: IRouter = {
  path: '/user-normal',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'userNormal.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UserOutlined />,
    activePath: 'user-normal',
  },
};
