export default {
  'groups.name': 'Nhóm người dùng',

  'groups.id': 'Mã nhóm',

  'groups.description': 'Thông tin nhóm',

  'groups.createdAt': 'Ngày tạo',

  'groups.applicationGroups': 'Hệ thống được phân quyền',

  'groups.action': 'Hành động',

  'groups.create': 'Tạo nhóm người dùng',

  'groups.delete': 'Xóa nhóm người dùng',

  'groups.userIds': 'Người dùng',

  'groups.update': 'Chỉnh sửa nhóm người dùng',

  'groups.information': 'Thông tin người dùng',

  'groups.content.delete': 'Bạn có chắc chắn muốn xóa nhóm người dùng này không?',

  'groups.applicationIds': 'Hệ thống được phân quyền',

  'groups.user': 'Thêm người dùng vào nhóm',

  'userNormal.group.fullName': 'Tên đầy đủ',
  'userNormal.group.name': 'Người dùng truy cập',
  'userNormal.group.emailAddress': 'Địa chỉ Email',
  'userNormal.group.groupsIds': 'Nhóm người dùng',
  'userNormal.group.gender': 'Giới tính',
  'userNormal.group.DOB': 'Ngày sinh',
  'userNormal.group.address': 'Địa chỉ',
  'userNormal.group.phoneNumber': 'Số điện thoại',
  'userNormal.group.idCard': 'Số CMND/CCCD',
  'userNormal.group.national': 'Quốc gia',
  'userNormal.group.avatar': 'Avatar',
  'userNormal.group.nfcCode': 'Mã thẻ',
  'userNormal.group.userName': 'Tên đăng nhập',
  'userNormal.group.status': 'Trạng thái hoạt động',
  'userNormal.group.password': 'Mật khẩu',
  'userNormal.group.password.confirm': 'Xác nhận mật khẩu',
  'userNormal.group.faces': 'Nhận diện khuôn mặt',
  'userNormal.group.action': 'Hành động',
  'group.user': 'Người dùng',
};
