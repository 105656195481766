export default {
  'Mes.Middlewares.IPAddress.Forbidden': '',
  'Mes.Oauth2.Get.Successfully': '',
  'Mes.Users.EmailAddress.AlreadyExist': 'Email người dùng đã tồn tại',
  'Mes.Users.CheckCode.Succesfully': 'Kiểm tra code thành công',
  'Mes.Users.UserGroupId.NotExist': 'UserGroupId người dùng đã tồn tại',
  'Mes.Users.EmailAddress.Invalid': 'Email người dùng không hợp lệ',
  'Mes.Users.EmailAddress.NotFound': 'Địa chỉ email không tồn tại',
  'Mes.Users.UserName.AlreadyExist': 'Tên đăng nhập đã tồn tại',
  'Mes.Users.Code.IsDuplicate': 'Code bị lặp lại',
  'Mes.Users.Code.Invalid': 'Code không hợp lệ',
  'Mes.Users.UserName.DoesNotExist': 'Tên đăng nhập chưa đã tồn tại',
  'Mes.Users.Id.NotFound': 'Người dùng đã bị xóa khỏi ứng dụng',
  'Mes.Users.NotFound': 'Không tìm được',
  'Mes.Users.Forbidden': 'Không tìm được',
  'Mes.Users.UserName.NotFound': 'Không tìm thấy username',
  'Mes.Users.IsLocked': 'Người dùng đã bị khóa',
  'Mes.Users.UserName.OverLength': 'Tên đăng nhập vượt quá độ dài cho phép',
  'Mes.Users.UserName.Invalid': 'Tên đăng nhập không hợp lệ',
  'Mes.Users.UserName.NotEmpty': 'Tên đăng nhập không để trống',
  'Mes.Users.Email.NotEmpty': 'Email không để trống',
  'Mes.Users.FullName.OverLength': '',
  'Mes.Users.FullName.NotEmpty': 'Tên đầy đủ không được phép là khoảng trắng',
  'Mes.Users.UserGroupId.NotEmpty': 'UserGroupId không để trống',
  'Mes.Users.FaceId.NotEmpty': 'FaceId không để trống',
  'Mes.Users.Face.NotEmpty': 'Face không để trống',
  'Mes.Users.UserGroupId.Invalid': 'UserGroupId không hợp lệ',
  'Mes.Users.FaceId.Invalid': 'FaceId không hợp lệ',
  'Mes.Users.Gender.NotNull': 'Giới tính không được để trống',
  'Mes.Users.Status.NotNull': 'Trạng thái không được để trống',
  'Mes.Users.Gender.Invalid': 'Giới tính không hợp lệ',
  'Mes.Users.Status.Invalid': 'Trạng thái không hợp lệ',
  'Mes.Users.DOB.NotNull': 'Ngày sinh không được để trống',
  'Mes.Users.Codes.NotNull': 'Codes không được để trống',
  'Mes.Users.IdCard.OverLength': 'Số CMND/CCCD vượt quá độ dài cho phép',
  'Mes.Users.National.OverLength': 'Quốc gia vượt quá độ dài cho phép',
  'Mes.Users.NFCCode.OverLength': 'Mã thẻ vượt quá độ dài cho phép',
  'Mes.Users.Address.OverLength': 'Địa chỉ vượt quá độ dài cho phép',
  'Mes.Users.IdCard.NotEmpty': 'Số CMND/CCCD không để trống',
  'Mes.Users.Id.IsRequired': 'Id người dùng là bắt buộc',
  'Mes.Users.Id.InValid': 'Id người dùng là không hợp lệ',
  'Mes.Users.Password.NotEmpty': 'Mật khẩu không để trống',
  'Mes.Users.ResetCode.NotValid': 'Reset code người dùng là không hợp lệ',
  'Mes.Users.Password.InValid': 'Mật khẩu không hợp lệ: trên 6 kí tự, it nhất 1 kí tự số',
  'Mes.Users.PhoneNumber.InValid': 'Số điện thoại không hợp lệ',
  'Mes.Users.Password.IsWrong': 'Mật khẩu sai',
  'Mes.Users.Password.OverLength': 'Mật khẩu không vượt quá giới hạn độ dài',
  'Mes.Users.PasswordConfirm.NotMatch': '',
  'Mes.Users.Avatar.InValid': 'Avatar không đúng định dạng',
  'Mes.Users.Face.InValid': 'Face không đúng định dạng',
  'Mes.Users.FaceCount.InValid': 'FaceCount không đúng định dạng',
  'Mes.Users.DeleteFace.InValid': 'Xóa hình ảnh không đúng',
  'Mes.Users.GetDetail.Successfully': 'Lấy thồng tin chi tiết người dùng thành công',
  'Mes.Users.GetProfile.Successfully': 'Lấy thồng tin người dùng thành công',
  'Mes.Users.GetAll.Successfully': 'Lấy tất cả thông tin người dùng thành công',
  'Mes.Users.Create.Successfully': 'Tạo thông tin người dùng thành công',
  'Mes.Users.Create.Failed': 'Tạo thông tin người dùng thất bại',
  'Mes.Users.Update.Successfully': 'Cập nhập thông tin người dùng thành công',
  'Mes.Users.Update.Failed': 'Cập nhập thông tin người dùng thất bại',
  'Mes.Users.Delete.Successfully': 'Xóa thông tin người dùng thành công',
  'Mes.Users.Delete.Failed': 'Xóa thông tin người dùng thất bại',
  'Mes.Roles.SendEmail.Successfully': 'Gửi email cho người dùng thành công',
  'Mes.Users.SignIn.Success': 'Đăng nhập thành công',
  'Mes.Users.ResetPassword.Succesfully': 'Đặt lại mật khẩu thành công',
  'Mes.Applications.Image.InValid': 'Tệp hình ảnh không đúng định dạng',
  'Mes.Applications.Name.IsRequired': 'Vui lòng nhập tên ứng dụng',
  'Mes.Applications.Document.IsRequired': '',
  'Mes.Applications.Name.OverLength': '',
  'Mes.Applications.RedirectUri.IsRequired': '',
  'Mes.Applications.RedirectUri.OverLength': '',
  'Mes.Applications.RedirectUri.InValid': 'Đường dẫn truy cập chưa đúng định dạng',
  'Mes.Applications.Webhook.OverLength': '',
  'Mes.Applications.ClientId.IsRequired': '',
  'Mes.Applications.Scope.IsRequired': '',
  'Mes.Applications.ClientSecret.IsRequired': '',
  'Mes.Applications.Id.IsRequired': '',
  'Mes.Applications.Id.NotFound': '',
  'Mes.Applications.Id.NotMatch': '',
  'Mes.Applications.RedirectUrl.NotMatch': '',
  'Mes.Applications.Get.Successfully': 'Lấy thông tin ứng dụng thành công',
  'Mes.Applications.Create.Successfully': 'Tạo ứng dụng thành công',
  'Mes.Applications.Update.Successfully': 'Cập nhật ứng dụng thành công',
  'Mes.Applications.Delete.Successfully': 'Xóa ứng dụng thành công',
  'Mes.Groups.Name.IsRequired': '',
  'Mes.Groups.ClientId.IsRequired': '',
  'Mes.Groups.ApplicationIds.IsRequired': '',
  'Mes.Groups.UserIds.IsRequired': '',
  'Mes.Groups.Scope.IsRequired': '',
  'Mes.Groups.ClientSecret.IsRequired': '',
  'Mes.Groups.RedirectUri.IsRequired': '',
  'Mes.Groups.Id.IsRequired': '',
  'Mes.Groups.Id.NotFound': '',
  'Mes.Groups.Id.NotMatch': '',
  'Mes.Groups.RedirectUrl.NotMatch': '',
  'Mes.Groups.Name.OverLength': '',
  'Mes.Groups.Id.OverLength': '',
  'Mes.Groups.Id.IsExisting': 'Mã nhóm đã tồn tại trong hệ thống',
  'Mes.Groups.Description.OverLength': '',
  'Mes.Groups.UserId.IsDuplicate': '',
  'Mes.Groups.ApplicationId.IsDuplicate': '',
  'Mes.Groups.ApplicationId.Invalid': '',
  'Mes.Groups.UserId.Invalid': '',
  'Mes.Groups.Get.Successfully': 'Lấy thồng tin nhóm thành công',
  'Mes.Groups.Create.Successfully': 'Tạo nhóm thành công',
  'Mes.Groups.Update.Successfully': 'Cập nhật nhóm thành công',
  'Mes.Groups.Delete.Successfully': 'Xóa nhóm thành công',
  'Mes.Auth.Code.IsExpired': '',
  'Mes.Auth.ClientSecret.Invalid': '',
  'Mes.Auth.RefreshToken.Invalid': '',
  'Mes.Auth.RedirectUri.Invalid': '',
  'Mes.ActivityLogs.Get.Successfully': 'Lấy thông tin nhật ký thành công',
  'Mes.ActivityLogs.Create.Successfully': '',
  'Mes.ActivityLogs.Update.Successfully': '',
  'Mes.ActivityLogs.Delete.Successfully': '',
  'Mes.FileStorage.File.IsRequired': '',
  'Mes.Users.PleaseCheckEmail': 'Xin vui lòng kiểm tra email của bạn',
  'Mes.Users.SendMail.Successfully': 'Mã khôi phục đã được gửi tới email của bạn',
  'Mes.Users.CheckRecoveryToken.Successfully': 'Mã khôi phục đúng',
  'Mes.Users.RecoveryCode.NotFound': 'Mã khôi phục không tồn tại',
  'Mes.Users.RecoveryCode.Expired': 'Mã khôi phục đã hết hạn',
  'Mes.Users.ResetForgotPassword.Successfully': 'Đã đổi mật khẩu thành công',
  'Mes.Users.NFCCode.AlreadyExist': 'Mã thẻ đã tồn tại',
  'Mes.Users.User.Inactive': 'Tài khoản đã bị ngưng hoạt động',
};
