import React from 'react';

import { IRouter } from '@routers/interface';

export const routerDocuments: IRouter = {
  path: '/documents',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'documents.name', //translate here for breadcrumb and sidebar
  masterLayout: false,
};
