export default {
  'profile.fullName': 'First and last name',
  'profile.userName': 'User name',
  'profile.phone': 'Phone number',
  'profile.internalComment': 'Note',
  'profile.brand': 'Brand',
  'profile.role': 'Role',
  'profile._password': 'Password',
  'profile.createDateTime': 'Date created',
  'profile.isActive': 'Operating status',
  'profile.change.password.title': 'Change the password',
  'profile.newPassword': 'A new password',
  'profile.confirm.newPassword': 'Confirm the new password',
  'password.not.match': 'The two passwords that you entered do not match',
  'profile.name': 'Profile',
  'profile.oldPassword': 'Old password',

  'info.fullName': 'Full Name',
  'info.birthday': 'Date of birth',
  'info.phoneNumber': 'Phone number',
  'info.cccd': 'Identify',
  'info.address': 'Address',
  'info.sex': 'Gender',
  'info.typeUser': 'Type of user',
  'info.email': 'Email',
  'info.country': 'Nation',
  'info.upload': 'Upload photos',
  'info-account': 'Account information',
  'info-code': 'Card information',
  'info-faceId': 'Face ID Information',
  'info-male': 'Male',
  'info-female': 'Female',

  'account.userName': 'User name',
  'account.code': 'ID Card',
  'account.faceId': 'Face ID',
  'tab.info': 'Information',
  'tab.account': 'Account',
  'change-pass': 'Change the password',
  'change-pass.message': 'Password must have number, uppercase letter and special character',
};
