export default {
  'users.name': 'Quản lý người dùng',
  'users.userName': 'Tên đăng nhập',
  'users.fullName': 'Tên đầy đủ',
  'users.phone': 'Số điện thoại',
  'users.isActive': 'Trạng thái',
  'users.action': 'Hành động',
  'users.internalComment': 'Ghi chú',
  'users.update': 'Cập nhật người dùng',
  'users.information': 'Thông tin người dùng',
  'users.password': 'Mật khẩu',
  'users.userType': 'Vai trò',
  'users.create': 'Tạo người dùng',
  'users.userType.admin': 'Quản trị',
  'users.userType.accountant': 'Kế toán',
  'users.userType.top.up': 'Nạp tiền',
  'transaction.order': 'Mua hàng',
  'users.userType.order': 'Bán hàng',
  'users.delete': 'Xóa người dùng',
  'user.content.delete': 'Bạn có chắc chắn muốn xóa người dùng này không?',
  'userNormal.codes': 'Phân quyền',

  'user.phoneNumber.notMatch': 'Số điện thoại không hợp lệ',

  // người dùng truy cập:
  'userNormal.fullName': 'Tên đầy đủ',
  'userNormal.name': 'Người dùng truy cập',
  'userNormal.emailAddress': 'Địa chỉ Email',
  'userNormal.groupsIds': 'Nhóm người dùng',
  'userNormal.gender': 'Giới tính',
  'userNormal.DOB': 'Ngày sinh',
  'userNormal.address': 'Địa chỉ',
  'userNormal.phoneNumber': 'Số điện thoại',
  'userNormal.idCard': 'Số CMND/CCCD',
  'userNormal.national': 'Quốc gia',
  'userNormal.avatar': 'Avatar',
  'userNormal.nfcCode': 'Mã thẻ',
  'userNormal.userName': 'Tên đăng nhập',
  'userNormal.status': 'Trạng thái hoạt động',
  'userNormal.password': 'Mật khẩu',
  'userNormal.password.confirm': 'Xác nhận mật khẩu',
  'userNormal.faces': 'Nhận diện khuôn mặt',
  'userNormal.action': 'Hành động',
  'userNormal.status.param':
    '{status,select, 2 {Dừng hoạt động} 1 {Đang hoạt động} other {Không xác định}}',
  'userNormal.information': 'Thông tin người dùng truy cập',
  'userNormal.account': 'Thông tin tài khoản',
  'userNormal.edit': 'Chỉnh sửa người dùng truy cập',
  'userNormal.info': 'Xem thông tin người dùng truy cập',
  'userNormal.add': 'Thêm người dùng truy cập',

  // người dùng hệ thống
  'userSSO.name': 'Người dùng hệ thống',
  'userSSO.information': 'Thông tin người dùng hệ thống',
  'userSSO.account': 'Thông tin tài khoản',
  'userSSO.edit': 'Chỉnh sửa người dùng hệ thống',
  'userSSO.info': 'Xem thông tin người dùng hệ thống',
  'userSSO.add': 'Thêm người dùng hệ thống',

  'user.avatar.size': 'Kích thước ảnh vượt quá 10Mb',
  'user.file.size': 'Kích thước file vượt quá 100Mb',
};
