export default {
  'roles.name': 'Quản lý vai trò',
  'roles.roleName': 'Tên vai trò',
  'roles.rolePermissions': 'Quyền',
  'roles.permissionCodes': 'Quyền',
  'roles.createdAt': 'Ngày tạo',
  'roles.action': 'Thao tác',
  'Per.Users.Create': 'Thêm user',
  'Per.Users.Delete': 'Xoá user',
  'Per.Users.Edit': 'Sửa user',
  'Per.Users.Export': 'Xuất user',
  'Per.Users.View': 'Xem user',
  'roles.create': 'Tạo vai trò',
  'roles.information': 'Thông tin vai trò',
  'roles.update': 'Chỉnh sửa vai trò',
  'Mes.Users.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Roles.Add.Successfully': 'Thêm vai trò thành công',

  'roles.createdBy': 'Tạo bởi',
  'roles.createDateTime': 'Ngày tạo',
  'roles.internalComment': 'Ghi chú',
  'roles.isActive': 'Trạng thái hoạt động',
  'roles.delete': 'Xoá vai trò',
  'roles.content.delete': 'Bạn có chắc chắn muốn xóa vai trò này không?',
  'roles.add.name': 'Thêm mới vai trò',
  'roles.info.name': 'Thông tin vai trò',
  'roles.edit.name': 'Cập nhật vai trò',

  'ActivityLogs':'Nhật ký hoạt động',
  'ActivityLogsView':'Xem Nhật ký hoạt động',
  'Applications':'Hệ thống',
  'ApplicationsCreate':'Tạo hệ thống',
  'ApplicationsDelete':'Xóa hệ thống',
  'ApplicationsUpdate':'Cập nhật hệ thống',
  'ApplicationsView':'Xem hệ thống',
  'UserGroups':'Người dùng truy cập',
  'UserGroupsCreate':'Tạo Người dùng truy cập',
  'UserGroupsDelete':'Xóa Người dùng truy cập',
  'UserGroupsUpdate':'Cập nhật Người dùng truy cập',
  'UserGroupsView':'Xem Người dùng truy cập',
  'Users':'Người dùng hệ thống',
  'UsersCreate':'Tạo Người dùng hệ thống',
  'UsersDelete':'Xóa Người dùng hệ thống',
  'UsersUpdate':'Cập nhật Người dùng hệ thống',
  'UsersView':'Xem Người dùng hệ thống',
};
