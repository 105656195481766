export default {
  'groups.name': 'User group',

  'groups.id': 'Group code',

  'groups.description': 'Group information',

  'groups.createdAt': 'Date created',

  'groups.applicationGroups': 'The system is decentralized',

  'groups.action': 'Act',

  'groups.create': 'Create a group of users',

  'groups.delete': 'Delete the user group',

  'groups.userIds': 'User',

  'groups.update': 'Edit the user group',

  'groups.information': 'User information',
  'groups.applicationIds': 'The system is decentralized',
  'groups.user': 'Add users to the group',

  // người dùng truy cập:
  'userNormal.group.fullName': 'Full name',
  'userNormal.group.name': 'User access',
  'userNormal.group.emailAddress': 'Email address',
  'userNormal.group.groupsIds': 'User group',
  'userNormal.group.gender': 'Gender',
  'userNormal.group.DOB': 'Date of birth',
  'userNormal.group.address': 'Address',
  'userNormal.group.phoneNumber': 'Phone number',
  'userNormal.group.idCard': 'Identify',
  'userNormal.group.national': 'Nation',
  'userNormal.group.avatar': 'Avatar',
  'userNormal.group.nfcCode': 'ID Card',
  'userNormal.group.userName': 'User name',
  'userNormal.group.status': 'Active status',
  'userNormal.group.password': 'Password',
  'userNormal.group.password.confirm': 'Confirm password',
  'userNormal.group.faces': 'Face Recognition',
  'userNormal.group.action': 'Act',
  'userNormal.group.status.param': '{status,select, 2 {UnActive} 1 {Active} other {Unknown}}',
  'userNormal.group.information': 'User information access',
  'userNormal.group.account': 'Account information',
  'userNormal.group.edit': 'Edit users to visit',
  'userNormal.group.info': 'See user information',
  'userNormal.group.add': 'Add more users',
  'group.user': 'Users',
};
