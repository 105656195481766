import { routerActivityLog } from '@view/ActivityLog/router';
import { routerForgotPassword, routerForgotToken } from '@view/Auth/ForgotPassword/router';
import { routerRootLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerResetPassword } from '@view/Auth/ResetPassword/router';
import { routerDocuments } from '@view/Documents/router';
import { routerGroupsAdd, routerGroupsUpdateInfo } from '@view/Groups/Pages/FormGroup/router';
import { routerGroups } from '@view/Groups/router';
import { routerHomeNormal } from '@view/HomeNormal/router';
import { routerPageError } from '@view/PageError/router';
import { routerSystemManagement } from '@view/SystemManagement/router';
import {
  routerFormUserNormal,
  routerFormUserNormalAdd
} from '@view/UserNormal/pages/FormUserNormal/router';
import { routerUserNormal } from '@view/UserNormal/router';
import { routerFormUserSSO, routerFormUserSSOAdd } from '@view/UserSSO/pages/FormUserNormal/router';
import { routerUserSSO } from '@view/UserSSO/router';

import { routerHomeSystem } from '../view/HomeSystem/router';
import { IRouter } from './interface';

export const privatePageNormal: IRouter[] = [
  routerHomeNormal,
  routerDocuments,
  routerViewProfile,
  routerPageError,
];

export const privatePageSystem: IRouter[] = [
  routerHomeSystem,
  routerSystemManagement,
  routerGroups,
  routerGroupsUpdateInfo,
  routerGroupsAdd,
  routerUserNormal,
  routerFormUserNormal,
  routerFormUserNormalAdd,
  routerViewProfile,
  routerUserSSO,
  routerFormUserSSOAdd,
  routerFormUserSSO,
  routerActivityLog,
  routerPageError,
];

export const publicPage: IRouter[] = [
  routerRootLogin,
  // routerLogin,
  routerForgotPassword,
  routerResetPassword,
  routerForgotToken,
  routerPageError,
];
