export default {
  'users.name': 'User management',
  'users.userName': 'User name',
  'users.fullName': 'Full name',
  'users.phone': 'Phone number',
  'users.isActive': 'Status',
  'users.action': 'Action',
  'users.internalComment': 'Note',
  'users.update': 'Update user',
  'users.information': 'User information',
  'users.password': 'Password',
  'users.userType': 'Role',
  'users.create': 'Create users',
  'users.userType.admin': 'Administration',
  'users.userType.accountant': 'Accountant',
  'users.userType.top.up': 'Recharge',
  'transaction.order': 'Purchase',
  'users.userType.order': 'Sell',
  'users.delete': 'Delete users',
  'user.content.delete': 'Are you sure you want to delete this user?',
  'userNormal.codes': 'Decentralization',

  // người dùng truy cập:
  'userNormal.fullName': 'Full name',
  'userNormal.name': 'User access',
  'userNormal.emailAddress': 'Email address',
  'userNormal.groupsIds': 'User group',
  'userNormal.gender': 'Gender',
  'userNormal.DOB': 'Date of birth',
  'userNormal.address': 'Address',
  'userNormal.phoneNumber': 'Phone number',
  'userNormal.idCard': 'Identify',
  'userNormal.national': 'Nation',
  'userNormal.avatar': 'Avatar',
  'userNormal.nfcCode': 'ID Card',
  'userNormal.userName': 'User name',
  'userNormal.status': 'Active status',
  'userNormal.password': 'Password',
  'userNormal.password.confirm': 'Confirm password',
  'userNormal.faces': 'Face Recognition',
  'userNormal.action': 'Act',
  'userNormal.status.param': '{status,select, 2 {UnActive} 1 {Active} other {Unknown}}',
  'userNormal.information': 'User information access',
  'userNormal.account': 'Account information',
  'userNormal.edit': 'Edit users to visit',
  'userNormal.info': 'See user information',
  'userNormal.add': 'Add more users',

  // người dùng hệ thống
  'userSSO.name': 'User of system',
  'userSSO.information': 'System information user',
  'userSSO.account': 'Account information',
  'userSSO.edit': 'Edit system users',
  'userSSO.info': "See system information' information ",
  'userSSO.add': 'Add system users',

  'user.phoneNumber.notMatch': 'invalid phone number',

  'user.avatar.size': 'Photo size exceeds 10Mb',
  'user.file.size': 'File size exceeds 100Mb',
};
