export default {
  'activityLog.name': 'Operational Diary',
  'activityLog.user.userName': 'User name',
  'activityLog.user.userType': 'Account type',
  'activityLog.user.userGroups': 'User group',
  'activityLog.application': 'Impact system',
  'activityLog.createdAt': 'Acting time',
  'activityLog.action': 'Operations',
  'activityLog.access-account': 'Accessed account',
  'activityLog.sso': 'Account SSO',
};
