export default {
  'roles.name': 'Manage role',
  'roles.roleName': 'Name role',
  'roles.rolePermissions': 'Permission',
  'roles.permissionCodes': 'Permission',
  'roles.createdAt': 'Date created',
  'roles.action': 'Manipulation',
  'Per.Users.Create': 'Add User',
  'Per.Users.Delete': 'Delete user',
  'Per.Users.Edit': 'Edit user',
  'Per.Users.Export': 'Export user',
  'Per.Users.View': 'View user',
  'roles.create': 'Create a role',
  'roles.information': 'Role information',
  'roles.update': 'Risen role',
  'Mes.Users.Role.UpdateSuccessfully': 'Update the role of success',
  'Mes.Roles.Add.Successfully': 'Add a success role',

  'roles.createdBy': 'Created by',
  'roles.createDateTime': 'Date created',
  'roles.internalComment': 'Note',
  'roles.isActive': 'Active status',
  'roles.delete': 'Stip off',
  'roles.content.delete': 'Are you definitely want to erase this role?',
  'roles.add.name': 'Add new role',
  'roles.info.name': 'Role information',
  'roles.edit.name': 'Role update',

  'ActivityLogs':'Activity diary',
  'ActivityLogsView':'View Active Diary',
  'Applications':'System',
  'ApplicationsCreate':'Create system',
  'ApplicationsDelete':'Delete the system',
  'ApplicationsUpdate':'System update',
  'ApplicationsView':'See the system',
  'UserGroups':'Users access',
  'UserGroupsCreate':'TCreate access users',
  'UserGroupsDelete':'Delete access users',
  'UserGroupsUpdate':'Update user access',
  'UserGroupsView':'See users access',
  'Users':'System users',
  'UsersCreate':'Create system users',
  'UsersDelete':'Delete system users',
  'UsersUpdate':'System user update',
  'UsersView':'View system users',
};
