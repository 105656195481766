import React from 'react';

import { IRouter } from '@routers/interface';

export const routerFormUserSSOAdd: IRouter = {
  path: '/user-sso/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'userSSO.add', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerFormUserSSO: IRouter = {
  path: '/user-sso/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'userSSO.edit', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
