export default {
  'documents.tab-1': 'Digitalization of the factory',
  'documents.guider': 'User manual',
  'documents.docs': 'Instruction document',
  'documents.system-directory': 'System catalog',

  'list-application': 'System list',
  'application-guider': 'See instructions for use',
  'documents.empty': 'There is no user manual ',
  'title.app-normal': 'Centralized login management system',
};
