import React from 'react';
import { IRouter } from '@routers/interface';
import { TeamOutlined } from '@ant-design/icons';

export const routerGroups: IRouter = {
  path: '/groups',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'groups.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <TeamOutlined />,
  },
};
