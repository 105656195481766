export default {
  'Mes.Middlewares.IPAddress.Forbidden': '',
  'Mes.Oauth2.Get.Successfully': '',
  'Mes.Users.EmailAddress.AlreadyExist': 'Email user exists',
  'Mes.Users.CheckCode.Succesfully': 'Check the code successfully',
  'Mes.Users.UserGroupId.NotExist': 'Usergroupid users have existed',
  'Mes.Users.EmailAddress.Invalid': 'Invalid user email',
  'Mes.Users.EmailAddress.NotFound': 'The email address does not exist',
  'Mes.Users.UserName.AlreadyExist': 'Username has existed',
  'Mes.Users.Code.IsDuplicate': 'The code is repeated',
  'Mes.Users.Code.Invalid': 'Invalid code',
  'Mes.Users.UserName.DoesNotExist': 'Username has not existed',
  'Mes.Users.Id.NotFound': 'Users have been deleted from the application',
  'Mes.Users.NotFound': 'Can not find',
  'Mes.Users.Forbidden': 'Can not find',
  'Mes.Users.UserName.NotFound': "Can't find username",
  'Mes.Users.IsLocked': 'User has been locked',
  'Mes.Users.UserName.OverLength': 'Username exceeds the allowed length',
  'Mes.Users.UserName.Invalid': 'Username is invalid',
  'Mes.Users.UserName.NotEmpty': 'Username does not leave blanks',
  'Mes.Users.Email.NotEmpty': 'Email without email',
  'Mes.Users.FullName.OverLength': '',
  'Mes.Users.FullName.NotEmpty': 'The full name is not allowed to be spaces',
  'Mes.Users.UserGroupId.NotEmpty': 'Usergroupid does not leave blanks',
  'Mes.Users.FaceId.NotEmpty': 'FaceID does not leave blanks',
  'Mes.Users.Face.NotEmpty': 'Face is not empty',
  'Mes.Users.UserGroupId.Invalid': 'UserGroupid is invalid',
  'Mes.Users.FaceId.Invalid': 'FaceID is invalid',
  'Mes.Users.Gender.NotNull': 'Gender must not leave blanks',
  'Mes.Users.Status.NotNull': 'Status not left blank',
  'Mes.Users.Gender.Invalid': 'Invalid gender',
  'Mes.Users.Status.Invalid': 'Invalid state',
  'Mes.Users.DOB.NotNull': 'Date of birth must not leave blanks',
  'Mes.Users.Codes.NotNull': 'Codes must not leave blanks',
  'Mes.Users.IdCard.OverLength': 'Idcard exceeds the allowed length',
  'Mes.Users.National.OverLength': 'Country exceeds the allowed length',
  'Mes.Users.NFCCode.OverLength': 'Nfccode exceeds the allowed length',
  'Mes.Users.Address.OverLength': 'The address exceeds the allowed length',
  'Mes.Users.IdCard.NotEmpty': 'Identify is not empty',
  'Mes.Users.Id.IsRequired': 'User ID is mandatory',
  'Mes.Users.Id.InValid': 'User ID is invalid',
  'Mes.Users.Password.NotEmpty': 'Password is not empty',
  'Mes.Users.ResetCode.NotValid': 'Reset user code is invalid',
  'Mes.Users.Password.InValid':
    'Invalid password: over 6 characters, items most 1 character number',
  'Mes.Users.PhoneNumber.InValid': 'Phonenumber is invalid',
  'Mes.Users.Password.IsWrong': 'Wrong password',
  'Mes.Users.Password.OverLength': 'Password does not exceed the length limit',
  'Mes.Users.PasswordConfirm.NotMatch': '',
  'Mes.Users.Avatar.InValid': 'Avatar incorrect format',
  'Mes.Users.Face.InValid': 'Face is not format',
  'Mes.Users.FaceCount.InValid': 'FaceCount improperly format',
  'Mes.Users.DeleteFace.InValid': 'Remove images incorrectly',
  'Mes.Users.GetDetail.Successfully': 'Get successful user details',
  'Mes.Users.GetProfile.Successfully': 'Get successful user news',
  'Mes.Users.GetAll.Successfully': 'Get all successful user information',
  'Mes.Users.Create.Successfully': 'Create successful user information',
  'Mes.Users.Create.Failed': 'Create information failure',
  'Mes.Users.Update.Successfully': 'Update successful user information',
  'Mes.Users.Update.Failed': 'Update user information failure',
  'Mes.Users.Delete.Successfully': 'Delete successful user information',
  'Mes.Users.Delete.Failed': 'Delete user information',
  'Mes.Roles.SendEmail.Successfully': 'Send email to successful users',
  'Mes.Users.SignIn.Success': 'Logged in successfully',
  'Mes.Users.ResetPassword.Succesfully': 'Successful password reset',
  'Mes.Applications.Image.InValid': 'Image files are not in the format',
  'Mes.Applications.Name.IsRequired': 'Please enter the application name',
  'Mes.Applications.Document.IsRequired': '',
  'Mes.Applications.Name.OverLength': '',
  'Mes.Applications.RedirectUri.IsRequired': '',
  'Mes.Applications.RedirectUri.OverLength': '',
  'Mes.Applications.Webhook.OverLength': '',
  'Mes.Applications.ClientId.IsRequired': '',
  'Mes.Applications.Scope.IsRequired': '',
  'Mes.Applications.ClientSecret.IsRequired': '',
  'Mes.Applications.Id.IsRequired': '',
  'Mes.Applications.Id.NotFound': '',
  'Mes.Applications.Id.NotMatch': '',
  'Mes.Applications.RedirectUrl.NotMatch': '',
  'Mes.Applications.Get.Successfully': 'Get successful application information',
  'Mes.Applications.Create.Successfully': 'Create successful application',
  'Mes.Applications.Update.Successfully': 'Successful application update',
  'Mes.Applications.Delete.Successfully': 'Delete the application successfully',
  'Mes.Groups.Name.IsRequired': '',
  'Mes.Groups.ClientId.IsRequired': '',
  'Mes.Groups.ApplicationIds.IsRequired': '',
  'Mes.Groups.UserIds.IsRequired': '',
  'Mes.Groups.Scope.IsRequired': '',
  'Mes.Groups.ClientSecret.IsRequired': '',
  'Mes.Groups.RedirectUri.IsRequired': '',
  'Mes.Groups.Id.IsRequired': '',
  'Mes.Groups.Id.NotFound': '',
  'Mes.Groups.Id.NotMatch': '',
  'Mes.Groups.RedirectUrl.NotMatch': '',
  'Mes.Groups.Name.OverLength': '',
  'Mes.Groups.Id.OverLength': '',
  'Mes.Groups.Id.IsExisting': 'Group code has existed in the system',
  'Mes.Groups.Description.OverLength': '',
  'Mes.Groups.UserId.IsDuplicate': '',
  'Mes.Groups.ApplicationId.IsDuplicate': '',
  'Mes.Groups.ApplicationId.Invalid': '',
  'Mes.Groups.UserId.Invalid': '',
  'Mes.Groups.Get.Successfully': 'Get successful group news',
  'Mes.Groups.Create.Successfully': 'Create a successful group',
  'Mes.Groups.Update.Successfully': 'Successful group update',
  'Mes.Groups.Delete.Successfully': 'Delete successful groups',
  'Mes.Auth.Code.IsExpired': '',
  'Mes.Auth.ClientSecret.Invalid': '',
  'Mes.Auth.RefreshToken.Invalid': '',
  'Mes.Auth.RedirectUri.Invalid': '',
  'Mes.ActivityLogs.Get.Successfully': 'Get successful diary information',
  'Mes.ActivityLogs.Create.Successfully': '',
  'Mes.ActivityLogs.Update.Successfully': '',
  'Mes.ActivityLogs.Delete.Successfully': '',
  'Mes.FileStorage.File.IsRequired': '',
  'Mes.Users.PleaseCheckEmail': 'Please check your email',
  'Mes.Users.SendMail.Successfully': 'The recovery code has been sent to your email',
  'Mes.Users.CheckRecoveryToken.Successfully': 'Code recovery correctly',
  'Mes.Users.RecoveryCode.NotFound': 'The recovery code does not exist',
  'Mes.Users.RecoveryCode.Expired': 'The recovery code has expired',
  'Mes.Users.ResetForgotPassword.Successfully': 'Change the password successfully',
  'Mes.Applications.RedirectUri.InValid': 'The path of access is not in the right format',
  'Mes.Users.NFCCode.AlreadyExist': 'The card code has existed',

  'Mes.Users.User.Inactive': 'The account has been stopped working',
};
