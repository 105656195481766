/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '@modules';
import UserEntity from '@modules/user/entity';
import { Selector } from '@reduxjs/toolkit';
import { imgAvatar, logo } from '@shared/assets/images';
import ChangeLanguage from '@shared/components/ChangeLanguage';
import { Dropdown, MenuProps } from 'antd';
import { useAltaIntl } from '@shared/hook/useTranslate';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteConfirm } from '@shared/components/ConfirmDelete';
import store from '@core/store/redux';
import { removeProfile } from '@modules/authentication/profileStore';

interface IHeaderComponent {
  profile?: UserEntity;
}

const HeaderComponentSelector: Selector<RootState, IHeaderComponent> = (state: RootState) => {
  return {
    profile: state.profile.user,
  };
};

const HeaderComponent: React.FC<{ hideLogo?: boolean }> = props => {
  const { profile } = useSelector(HeaderComponentSelector);
  const navigate = useNavigate();
  const { formatMessage } = useAltaIntl();

  const items: MenuProps['items'] = React.useMemo(() => {
    return [
      {
        key: 'no',
        label: (
          <div className="wrapper__label">
            <div className="header-component__identify">
              <h4 className="identify__admin">{profile?.userName}</h4>
              <p className="identify__hi">{profile?.role || 'Unknown'}</p>
            </div>
          </div>
        ),
      },
      {
        type: 'divider',
      },
      {
        key: '0',
        label: (
          <div className="wrapper__labelIcon">
            <UserOutlined />
            <span className="menu-label">{formatMessage('common.profile')}</span>
          </div>
        ),
      },
      {
        type: 'divider',
      },
      {
        key: '2',
        label: (
          <div className="wrapper__labelIcon">
            <LogoutOutlined />
            <span className="menu-label">{formatMessage('common.logout')}</span>
          </div>
        ),
      },
    ];
  }, [profile]);

  const onMenuSelect = ({ key }: any) => {
    switch (key) {
      case '0':
        navigate('/profile');
        break;
      case '1':
        break;
      case '2':
        DeleteConfirm({
          title: formatMessage('common.logout.title'),
          content: formatMessage('common.logout.content'),
          handleOk: () => {
            store.dispatch(removeProfile());
            navigate('/');
          },
        });
        break;
      default:
        return;
    }
  };

  const gotoPage = (path: string) => () => {
    navigate(path);
  };

  return (
    <div className="wrapper_header">
      <div className="header-logo">
        {props.hideLogo !== true && (
          <img className="w-100 h-100" src={logo} onClick={gotoPage('/')} />
        )}
      </div>
      <div className="header-component item-hover__icon intro-x">
        <ChangeLanguage />
        <div className="d-flex user-info">
          <span className="mr-3">{profile?.fullName}</span>
          <Dropdown
            placement="bottomRight"
            trigger={['click']}
            menu={{
              items,
              onClick: onMenuSelect,
            }}
            overlayClassName={'header-component__dropdown-menu'}
          >
            <div className="dropdown__profile__img">
              <img alt="img-avatar" className="img-avatar" src={profile?.avatar || imgAvatar} />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default memo(HeaderComponent);
