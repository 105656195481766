export default {
  'profile.fullName': 'Họ và tên',
  'profile.userName': 'Tên đăng nhập',
  'profile.phone': 'Số điện thoại',
  'profile.internalComment': 'Ghi chú',
  'profile.brand': 'Nhãn hiệu',
  'profile.role': 'Vai trò',
  'profile._password': 'Mật khẩu',
  'profile.createDateTime': 'Ngày tạo',
  'profile.isActive': 'Trạng thái hoạt động',
  'profile.change.password.title': 'Thay đổi mật khẩu',
  'profile.newPassword': 'Mật khẩu mới',
  'profile.confirm.newPassword': 'Xác nhận mật khẩu mới',
  'password.not.match': 'Hai mật khẩu bạn đã nhập không khớp',
  'profile.name': 'Hồ sơ',
  'profile.oldPassword': 'Mật khẩu cũ',

  'info.fullName': 'Họ và tên',
  'info.birthday': 'Ngày sinh',
  'info.phoneNumber': 'Số điện thoại',
  'info.cccd': 'CMND/CCCD',
  'info.address': 'Địa chỉ',
  'info.sex': 'Giới tính',
  'info.typeUser': 'Loại người dùng',
  'info.email': 'Email',
  'info.country': 'Quốc gia',
  'info.upload': 'Tải ảnh lên',
  'info-account': 'Thông tin tài khoản',
  'info-code': 'Thông tin thẻ',
  'info-faceId': 'Thông tin Face ID',
  'info-male': 'Nam',
  'info-female': 'Nữ',

  'account.userName': 'Tên đăng nhập',
  'account.code': 'Mã thẻ',
  'account.faceId': 'Face ID',
  'tab.info': 'Thông tin',
  'tab.account': 'Tài khoản',
  'change-pass': 'Thay đổi mật khẩu',

  'change-pass.message': 'Mật khẩu phải có số, chữ hoa, thường và kí tự đặc biệt',
};
