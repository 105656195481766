export default {
  'systemManagement.router.name': 'System management',
  'systemManagement.name': 'System name',
  'systemManagement.image': 'Picture',
  'systemManagement.clientSecret': 'Security code',
  'systemManagement.redirectUri': 'Access path',
  'systemManagement.totalUserAccess': 'Authorized account number',
  'systemManagement.action': 'Action',
  'systemManagement.create': 'Add system',
  'systemManagement.webhook': 'webhook',
  'systemManagement.id': 'System identification code',
  'systemManagement.documentFile': 'Instruction document (PDF)',
  'systemManagement.not': 'Do not enter this school',
  'systemManagement.information': 'System information',
  'systemManagement.update': 'System update',
  'systemManagement.content.delete': 'Are you sure you want to delete this system?',
  'systemManagement.nameVN': 'Vietnamese name system',
  'systemManagement.nameEN': 'English name system',
  'systemManagement.documentFileVN': 'Vietnam Guide (PDF)',
  'systemManagement.documentFileEN': 'English Guide (PDF)',
  'systemManagement.regex': 'Regex',

  'systemManagement.descriptionVN': 'English description',
  'systemManagement.descriptionEN': 'Vietnamese description',
  'systemManagement.order': 'Order',

};
