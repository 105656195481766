import moment from 'moment';

import RoleEntity from '@modules/roles/entity';

export enum UserType {
  USER_NORMAL = 1,
  USER_SSO = 2,
}

export class UserPermissionEntity {
  accountPermissionId: string = '';

  permissionCode = '';

  accountId = '';

  accountPermissionCreateAt = '';

  constructor(permission: Partial<UserPermissionEntity>) {
    if (!permission) {
      return;
    }
    Object.assign(this, permission);
  }

  static createlistPermisison(list?: Array<Partial<UserPermissionEntity>>) {
    if (list === undefined) {
      return undefined;
    }
    return list.map(ll => {
      return new UserPermissionEntity(ll);
    });
  }
}

class UserEntity {
  userName = '';

  firstName = '';

  lastName = '';

  fullName = '';

  permissions: UserPermissionEntity[] | undefined = [];

  permissionResponses: any[] = [];

  gender = '';

  dob = '';

  address = '';

  id = '';

  phoneNumber?: string = '';

  idCard?: any;

  createdAt = '';

  roleId?: any;

  role?: RoleEntity;

  avatar?: string = '';

  nfcCode?: string = '';

  national?: string = '';

  isActive?: boolean = false;

  status: number = 0;

  userType?: UserType;

  userGroups?: any;

  faces?: any;

  value? = '';

  label? = '';

  constructor(user?: Partial<UserEntity>) {
    if (!user) {
      return;
    }
    Object.assign(this, user);

    this.createdAt = user?.createdAt ? moment(user?.createdAt).format('DD/MM/YYYY HH:mm:ss') : '';
    this.value = user.id ? user.id : '';
    this.label = user.userName ? user.userName : '';
  }

  static createArrayUser(arrUser: Array<Partial<UserEntity>>): Array<UserEntity> {
    if (!arrUser) {
      return [];
    }
    return arrUser.map(x => new UserEntity(x));
  }
}

export default UserEntity;
