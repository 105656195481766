import '@shared/assets/css/animation.css';
import '@styles/styles.scss';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import React, { memo, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import locale from '@locale/index';
import { TokenSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import PrivatePage from '@routers/component/PrivatePage';
import PublicPage from '@routers/component/PublicPage';
import ThemeContext from '@shared/hook/ThemeContext';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utc);

const MainView = memo(({ statusLogin }: { statusLogin: boolean }) => {
  return <>{statusLogin ? <PrivatePage /> : <PublicPage />}</>;
});

const App: React.FC = () => {
  const { token } = useSelector(TokenSelector);
  const { language } = useSelector(LanguageSelector);
  // const navigate = useNavigate();
  // const location = useLocation();

  // const queryParams = useQueryParams();

  const memoLangData = useMemo(() => {
    return locale[language];
  }, [language]);

  // useEffect(() => {
  //   if (lodash.isEmpty(token)) {
  //     navigate({ pathname: '/login', search: toSearch(queryParams) });
  //     if (location.pathname.split('/', 2).some(p => p === 'reset-password')) {
  //       navigate({ pathname: location.pathname, search: toSearch(queryParams) });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);

  const initStyle = React.useMemo(()=>{
    return {
      colorPrimary: '#005556',
      colorPrimaryLight: '#338d8f',
      colorText: '#4f4c4d',
      colorTextSecondary: '#fff',
      colorLink: '#000',
      colorBgContainer: '#fff',
      colorBgLayout: '#f5f5f5',
      fontFamily: 'Roboto',
      colorError: 'red',
      colorSuccess: '#0bda06',
      colorWarning: '#ffb800',
      colorTextBase: '#000',
      colorTextLightSolid: '#fff',
    };
  }, []);

  return (
    <IntlProvider locale={language} messages={memoLangData}>
      <ThemeContext token={initStyle}>
        <MainView statusLogin={token !== ''} />
      </ThemeContext>
    </IntlProvider>
  );
};

export default App;
