import React, { memo, PropsWithChildren } from 'react';

import HeaderComponent from './Header';
import SiderComponent from './Sidebar';
import { Skeleton } from 'antd';

interface IDefaultLayoutProps {
  history?: any;
  hideSideBar?: boolean;
  hideHeader?: boolean;
  loading?: boolean;
}

const DefaultLayout: React.FC<PropsWithChildren<IDefaultLayoutProps>> = props => {
  // const [menu, setMenu] = useState<string>('sider-component big');

  // const onClick = (e: any): any => {
  //   const targetNode = e.target as HTMLDivElement;
  //   if (targetNode.className === 'main-component' || e.target === e.currentTarget) {
  //     setMenu('sider-component ');
  //     return;
  //   }
  //   if (
  //     targetNode.tagName === 'INPUT' ||
  //     targetNode.tagName === 'BUTTON' ||
  //     targetNode.parentElement?.onclick !== null
  //   ) {
  //     return;
  //   }
  //   setMenu('sider-component');
  // };

  return (
    <div className="all-page-component">
      {props.hideSideBar !== true && <SiderComponent setClassName={()=>{}} className={'sider-component big'} />}
      <div className="right-page-component" >
        {props.hideHeader !== true && (
          <div className="w-100 d-flex header">
            <HeaderComponent hideLogo={props.hideSideBar !== true} />
          </div>
        )}
        { props.loading ? <Skeleton className='mt-5' avatar active loading={true} paragraph={{ rows: 9 }}/> : <div className={'main-component'}>{props.children}</div>}
      </div>
    </div>
  );
};

export default memo(DefaultLayout);
