export default {
  'documents.tab-1': 'Số hóa  nhà máy',
  'documents.guider': 'Hướng dẫn sử dụng',
  'documents.docs': 'Tài liệu hướng dẫn sử dụng',
  'documents.system-directory': 'Danh mục hệ thống',
  'list-application': 'Danh sách hệ thống',
  'application-guider': 'Xem hướng dẫn sử dụng',
  'documents.empty': 'Không có tài liệu hướng dẫn sử dụng',

  'title.app-normal': 'HỆ THỐNG QUẢN LÝ ĐĂNG NHẬP TẬP TRUNG',
};
