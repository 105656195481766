export default {
  'activityLog.name': 'Nhật ký hoạt động',
  'activityLog.user.userName': 'Tên đăng nhập',
  'activityLog.user.userType': 'Loại tài khoản',
  'activityLog.user.userGroups': 'Nhóm người dùng',
  'activityLog.application': 'Hệ thống tác động',
  'activityLog.createdAt': 'Thời gian tác động',
  'activityLog.action': 'Thao tác thực hiện',
  'activityLog.access-account': 'Tài khoản truy cập',
  'activityLog.sso': 'Tài khoản SSO',
};
