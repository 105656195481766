import React from 'react';
import { IRouter } from '@routers/interface';
import { QrcodeOutlined } from '@ant-design/icons';

export const routerSystemManagement: IRouter = {
  path: '/system-management',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'systemManagement.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <QrcodeOutlined />,
  },
};
