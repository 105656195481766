import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Routes, useLocation, useSearchParams } from 'react-router-dom';
import lodash from 'lodash';
import DefaultLayout from '@layout/index';
import authenticationPresenter from '@modules/authentication/presenter';
import { TokenSelector } from '@modules/authentication/profileStore';
import { UserType } from '@modules/user/entity';
import { IRouter } from '@routers/interface';
import { privatePageNormal, privatePageSystem } from '@routers/mainRouter';
import { useSingleAsync } from '@shared/hook/useAsync';

import useRouter from './useRouter';

const PrivatePage: React.FC = () => {
  const { token, code } = useSelector(TokenSelector);
  const location = useLocation();
  const getProfile = useSingleAsync(authenticationPresenter.getProfile);

  const [privatePage, setPrivatePage] = useState<IRouter[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(()=>{
    const params = Object.fromEntries(searchParams.entries());
    if (code !== '' && (!lodash.isEmpty(params.redirectUrl) || !lodash.isEmpty(params.redirecturl) || !lodash.isEmpty(params.redirect_url))) {
      window.location.href = `${params.redirectUrl || params.redirecturl || params.redirect_url}?code=${code}`;
    }
  }, [searchParams, code]);

  useEffect(() => {
    if (token) {
      getProfile.execute().then(res => {
        setPrivatePage(
          res.userType === UserType.USER_NORMAL ? privatePageNormal : privatePageSystem,
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const { views, routes } = useRouter({
    routers: privatePage,
    privateRoute: true,
  });

  const showMasterLayout = React.useMemo(() => {
    const r = routes.find(it => matchPath(it.path, location.pathname));
    if (r === undefined) {
      return { hideSideBar: true, hideHeader: true };
    }
    if (r?.masterLayout == null) {
      return { hideSideBar: false, hideHeader: false };
    } else if (r?.masterLayout === false) {
      return { hideSideBar: true, hideHeader: true };
    } else if (typeof r?.masterLayout === 'object') {
      return { hideSideBar: r?.masterLayout?.hideSideBar ? true : false, hideHeader: r?.masterLayout?.hideHeader ? true : false };
    }
    return { hideSideBar: false, hideHeader: false };
  }, [location.pathname, routes]);

  return (
    <DefaultLayout
      hideSideBar={showMasterLayout.hideSideBar}
      hideHeader={showMasterLayout.hideHeader}
      loading={getProfile.status !== 'ready'}
    >
      <Routes>{views}</Routes>
    </DefaultLayout>
  );
};
export default React.memo(PrivatePage);


