import viVN from 'antd/lib/locale/vi_VN';

import activityLog from './activityLog';
import auth from './auth';
import common from './common';
import documents from './documents';
import Form from './form';
import groups from './groups';
import pageError from './pageError';
import roles from './roles';
import server from './server';
import systemManagement from './systemManagement';
import user from './user';

export default {
  Form,
  ...viVN,
  ...common,
  ...server,
  ...auth,
  ...pageError,
  ...roles,
  ...user,
  ...documents,
  ...systemManagement,
  ...groups,
  ...activityLog,
};
