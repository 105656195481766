import React from 'react';
import { IRouter } from '@routers/interface';

export const routerGroupsAdd: IRouter = {
  path: '/groups/form-group/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'groups.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerGroupsUpdateInfo: IRouter = {
  path: '/groups/form-group/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'groups.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
