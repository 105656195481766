import enUS from 'antd/lib/locale/en_US';

import activityLog from './activityLog';
import auth from './auth';
import common from './common';
import documents from './documents';
import groups from './groups';
import pageError from './pageError';
import roles from './roles';
import server from './server';
import systemManagement from './systemManagement';
import user from './user';

export default {
  ...enUS,
  ...common,
  ...server,
  ...auth,
  ...pageError,
  ...roles,
  ...user,
  ...documents,
  ...systemManagement,
  ...activityLog,
  ...groups
};
