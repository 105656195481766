export const logo = require('./logo.png');
export const imgAvatar = require('./avatar.jpg');
export const languageVN = require('./vn.png');
export const languageEN = require('./en.png');
export const Img404 = require('./error.svg');
export const ImgLogin = require('./img_login.svg');

export { default as ArrowLeftImg } from './arrow-left.png';
export { default as SSOImg } from './sso.png';
export { default as NoAvatar } from './no-avatar.png';

export const AppLogo = require('./inventory.jpg');
export const ImgAppLogo = require('./img_graphic.png');
export const AppBanner = require('./application-integration-banner.jpeg');
